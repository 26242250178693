import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Home from './pages/Home';
import Downgrade from './pages/Downgrade';
import Redirect from './pages/Redirect';
import SubscriptionSuccess from './pages/Success';
import Terms from './pages/Terms';
import './index.css'
import { userAtom } from './recoil/userAtoms'; // Import your sidebarState
import { useRecoilValue } from 'recoil'; // Import useRecoilState

function App() {
  const user = useRecoilValue(userAtom);
  

  return (
    <Router>
      <div className="App">
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/terms' element={<Terms/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/redirect' element={<Redirect/>}/>
        <Route path='/:user_id' element={<Home/>}/>
        <Route path="/success/:user_id" exact element={<SubscriptionSuccess/>} />
      </Routes>
      </div>
    </Router>
  );
}

export default App;