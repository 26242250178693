import { 
    Box, 
    Flex,
    Text,
    InputGroup,
    InputRightAddon,
    Image,
    Button,
    Input,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { useRef, useState } from 'react';
import axios from 'axios';
import { FaRegEye } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseApp } from '../firebase/firebaseConfig'


const LoginComponent = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  
  const history = useNavigate();

  const [_, setUser] = useRecoilState(userAtom);

  const [showPassword, setShowPassword] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const [err201, seterr201] = useState(false);
  const [errNetwork, seterrNetwork] = useState(false);

  const handleClick = () => {
    setDataLoading(true);
    
    const auth = getAuth(firebaseApp);
    signInWithEmailAndPassword(auth, emailRef.current.value.trim(), passwordRef.current.value)
      .then((userCredential) => {
        var user = userCredential.user;
        console.log(user);
        // Check if email matches "locseatapp@gmail.com"
          // Set the user state to be the authenticated user's data
          axios.post('https://locseat-backend-e47bf5c07959.herokuapp.com' + "/api/login", {
            email: emailRef.current.value.trim(),
          }).then(res => {
            seterr201(false);
            setUser(res.data.user)
            history('/home')
            console.log('user logged');
            setDataLoading(false);
          }).catch(err => {
            seterr201(true);
            console.log(err)
            alert("Invalid credentials")
            setDataLoading(false);
          });
      
      })
      .catch((error) => {
        console.log(error)
        
        // Handle the error here - for example, you could set an error state
        seterr201(true);
        setDataLoading(false);
      });
  };
  





  return (
    <Box bg='black' height={'100vh'}>
        <Box w={["95%", "40vw"]} margin='0 auto' pt={['15vh', '10vh']}>
          <Flex direction='column' color='#000'>
            <Flex w='50%' m='0 auto'>
              <Image src='./logo-no-background.png'/>
            </Flex>
              <Text textAlign='center' fontWeight='600' fontSize='0.8rem' color={'white'} mb={6}>SUBSCRIPTION PORTAL</Text>
              {err201 && 
                <Box p={4} textAlign='center'>
                  <Text color='red.300'>Something went wrong. Check your inputs and try again.</Text>  
                </Box>
              }
              
              <Flex direction={'column'} px={8} bg={'#212121'} py={12} borderRadius={8}>
                <Box mb={4}>

                <Text color={'white'} fontSize={'2.5rem'} fontWeight={'extrabold '}>LOGIN</Text>

                  <Flex justifyContent='space-between'>
                    <Text color={'white'}>Email</Text>
                  </Flex>
                  <Input ref={emailRef}
                  focusBorderColor="transparent"
                  outline="none"   
                  color={'white'}
                  borderColor={'transparent'}
                      bg='#2e2e2e'               
                      />
                </Box>

                <Box mb={4}>
                  <Flex justifyContent='space-between'>
                    <Text color={'white'}>Password</Text>
                  </Flex>
                  <InputGroup>
                    <Input 
                      ref={passwordRef}    
                      bg='#2e2e2e'    
                      focusBorderColor="transparent"
                      outline="none"        
                      borderColor={'transparent'}
                      color={'white'}


                      type={showPassword?'':'password'} 
                    />
                    <InputRightAddon
                      _hover={{ cursor: 'pointer'}}  
                      bg={'#1a1a1a'}
                      borderColor={'transparent'}

                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <FaRegEye color='gray'/>
                    </InputRightAddon>              
                  </InputGroup>
                </Box>
                <Box>
                  <Button 
                    isLoading={isDataLoading}
                    _hover={{ bg: '#883D1A' }} 
                    bg="#883D1A" 
                    w='100%' 
                    color='white' 
                    fontWeight='800' 
                    fontSize='1.1rem'
                      onClick={() =>handleClick()}
                  >
                    LOGIN
                  </Button>
                  <Text mt={2} color='gray' textAlign={'center'}>Don't have an account? <a href='https://locseat.com' style={{color: '#883D1A', textDecoration: 'none'}}>Download</a> the Locseat app.</Text>  

                </Box>

              </Flex>
              
          </Flex>
        </Box>
    </Box>
  )
}

export default LoginComponent