import { 
    Box, 
    Flex,
    Text,
    InputGroup,
    InputRightAddon,
    Image,
    Button,
    Skeleton,
    Input,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaRegEye } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { getAuth, signOut } from "firebase/auth";
import { firebaseApp } from '../firebase/firebaseConfig'
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';


const Home = () => {
  const { user_id } = useParams();
  const [isLoading, setIsLoading] = useState(false)

  const history = useNavigate();

  const [user, setUser] = useRecoilState(userAtom);
  
  const [status, setStatus] = useState('')
  const [trialEnds, setTrialEnds] = useState('')
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [invoices, setInvoices] = useState([])
  const [nextPaymentDate, setNextPaymentDate] = useState([])

  const fetchStatus = async () => {
    setIsLoading(true)
      try {
        const response = await axios.get('https://locseat-backend-e47bf5c07959.herokuapp.com' + `/api/loctician/subscription-data/${user_id=='home'?user._id:user_id}`);
        setStatus(response.data.status); 
        setTrialEnds(response.data.trialEnds); 
        setNextPaymentDate(response.data?.nextPaymentDate)
        setInvoices(response.data?.invoices)

        console.log(response.data);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    setIsLoading(false)
    };

  useEffect(() => {
    

    fetchStatus();
  }, [user_id]);

  const handleLogout = async () => {
    const auth = getAuth(firebaseApp);
    signOut(auth);
    setUser({}); // Clear the user information on logout
    history('/')

  };

  const activateProSubscription = async () => {
    setCheckoutLoading(true);
    try {
      const response = await axios.post('https://locseat-backend-e47bf5c07959.herokuapp.com' + '/api/create-checkout-session', {
        _id: user_id=='home'?user._id:user_id
      });

      // Use the session URL to redirect the user to Stripe's checkout page
      const sessionURL = response.data.sessionURL;
      window.location.href = sessionURL;
    } catch (error) {
      console.error("Error creating Stripe checkout session:", error);
      // Handle the error appropriately for the user
    }
    setCheckoutLoading(false);
  }

  const redirectToStripePortal = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://locseat-backend-e47bf5c07959.herokuapp.com' + '/api/stripe/customer-portal', {
        _id: user_id=='home'?user._id:user_id
      });

      // Use the session URL to redirect the user to Stripe's portal
      const portalURL = response.data.redirect;
      window.location.href = portalURL;
    } catch (error) {
      console.error("Error creating Stripe portal session:", error);
      // Handle the error appropriately for the user
    }
    setIsLoading(false);
}



  return (
    <Box bg='black' height={'100vh'}>
        <Skeleton isLoaded={!isLoading}>
        <Box w={["100%", "100%"]} margin='0 auto' >
            <Flex justifyContent={'space-between'} pb={2} px={2} mb={6}>
              <Image src='./logo-no-background.png' w={['50%', '20%']}/>
              <Text color={'#883D1A'} cursor={'pointer'} fontWeight={'extrabold'} mt={4} onClick={() => handleLogout()}>LOGOUT</Text>
            </Flex>
          <Flex direction='column' color='#000' m={'0 auto'} w={["100%", "40%"]}>
              
              {status == 'freeTrial' && 
              <Flex direction={'column'}>
                <Flex direction={'column'} px={6} bg={'#212121'} py={8} borderRadius={8} mx={4} my={2} onClick={activateProSubscription}>
                    <Box>
                        <Text color={'white'} fontSize={'1.2rem'} fontWeight={'extrabold'} textAlign={'center'}>{checkoutLoading ? "Loading..." : "ACTIVATE PRO SUBSCRIPTION"}</Text>
                    </Box>
                </Flex>

                <Flex direction={'column'} px={6} bg={'#212121'} py={8} borderRadius={8} mx={4} my={2}>
                    
                    <Flex justifyContent={'space-between'} textColor={'#fff'} mb={4}>
                        <Text fontSize={'1.2rem'} fontWeight={'extrabold'}>SUBSCRIPTION</Text>
                        <Text fontSize={'0.8rem'} fontWeight={'extrabold'} color={'#883D1A'}>END TRIAL</Text>
                    </Flex>

                    <Flex direction={'column'}>
                        <Text fontWeight={'extrabold'} color={'gray'} fontSize={'0.8rem'}>CURRENT PLAN</Text>
                        <Text fontWeight={'bold'} color={'white'} mb={2}>PRO</Text>
                        <Text fontWeight={'extrabold'} color={'gray'} fontSize={'0.8rem'}>STATUS</Text>
                        <Text fontWeight={'bold'} color={'#883D1A'}>TRIAL - Ends {trialEnds}</Text>
                    </Flex>

                </Flex>

                <Flex direction={'column'} px={6} bg={'#212121'} py={8} borderRadius={8} mx={4} my={2}>
                    
                    <Flex direction={'column'}>
                        <Text fontWeight={'extrabold'} color={'gray'} fontSize={'1rem'} textAlign={'center'}>You have no past or upcoming payments</Text>
                    </Flex>
                </Flex>
              </Flex>}

              {status == 'pro' && 
              <Flex direction={'column'}>
                <Flex direction={'column'} px={6} bg={'#212121'} py={12} borderRadius={8} mx={4} my={2} onClick={redirectToStripePortal}>
                    <Box >
                    <Text color={'red.500'} fontSize={'1.2rem'} fontWeight={'extrabold '} textAlign={'center'}>
                      {isLoading ? "Loading..." : "CANCEL PRO SUBSCRIPTION"}
                    </Text>
                    </Box>
                </Flex>

                <Flex direction={'column'} px={6} bg={'#212121'} py={12} borderRadius={8} mx={4} my={2}>
                    
                    <Flex justifyContent={'space-between'} textColor={'#fff'} mb={4}>
                        <Text fontSize={'1.2rem'} fontWeight={'extrabold'}>SUBSCRIPTION</Text>
                        <Text fontSize={'0.8rem'} fontWeight={'extrabold'} color={'#883D1A'} onClick={redirectToStripePortal}>CANCEL</Text>
                    </Flex>

                    <Flex direction={'column'}>
                        <Text fontWeight={'extrabold'} color={'gray'} fontSize={'0.8rem'}>CURRENT PLAN</Text>
                        <Text fontWeight={'bold'} color={'white'} mb={2}>PRO</Text>
                        <Text fontWeight={'extrabold'} color={'gray'} fontSize={'0.8rem'}>STATUS</Text>
                        <Text fontWeight={'bold'} color={'#883D1A'}>ACTIVE - Next Payment {format(new Date(nextPaymentDate * 1000), 'MM/dd/yyyy')}</Text>
                    </Flex>

                </Flex>

                <Flex direction={'column'} px={6} bg={'#212121'} py={12} borderRadius={8} mx={4} my={2}>
                        
                    <Flex justifyContent={'space-between'} textColor={'#fff'} mb={4}>
                        <Text fontSize={'1.2rem'} fontWeight={'extrabold'}>PAYMENT HISTORY</Text>
                    </Flex>

                    {invoices && invoices.length > 0 ? (
                        invoices.map(invoice => (
                            <Flex justifyContent={'space-between'} mb={4} key={invoice.id}>
                                <Text fontWeight={'extrabold'} color={'gray'} fontSize={'1rem'}>
                                    {format(new Date(invoice.invoice_date * 1000), 'MM/dd/yyyy')}
                                </Text>
                                <Text fontWeight={'bold'} color={'white'}>${invoice.amount.toFixed(2)}</Text>
                                <Text fontWeight={'bold'} color={'white'}>{invoice.status.toUpperCase()}</Text>
                            </Flex>
                        ))
                    ) : (
                        <Text fontWeight={'extrabold'} color={'gray'} fontSize={'1rem'} textAlign={'center'}>
                            You have no past or upcoming payments
                        </Text>
                    )}
                </Flex>
              </Flex>}

              {status == 'lite' && 
              <Flex direction={'column'}>
                <Flex direction={'column'} px={6} bg={'#212121'} py={12} borderRadius={8} mx={4} my={2}>
                    <Box >
                        <Text color={'white'} fontSize={'1.2rem'} fontWeight={'extrabold '} textAlign={'center'} onClick={activateProSubscription}>{checkoutLoading ? "Loading..." : "UPGRADE TO PRO SUBSCRIPTION"}</Text>
                    </Box>
                </Flex>

                <Flex direction={'column'} px={6} bg={'#212121'} py={12} borderRadius={8} mx={4} my={2}>
                    
                    <Flex justifyContent={'space-between'} textColor={'#fff'} mb={4}>
                        <Text fontSize={'1.2rem'} fontWeight={'extrabold'}>SUBSCRIPTION</Text>
                        <Text fontSize={'0.8rem'} fontWeight={'extrabold'} color={'#883D1A'} onClick={activateProSubscription}>UPGRADE</Text>
                    </Flex>

                    <Flex direction={'column'}>
                        <Text fontWeight={'extrabold'} color={'gray'} fontSize={'0.8rem'}>CURRENT PLAN</Text>
                        <Text fontWeight={'bold'} color={'white'} mb={2}>LITE</Text>
                        <Text fontWeight={'extrabold'} color={'gray'} fontSize={'0.8rem'}>STATUS</Text>
                        <Text fontWeight={'bold'} color={'#883D1A'}>ACTIVE</Text>
                    </Flex>

                </Flex>

                <Flex direction={'column'} px={6} bg={'#212121'} py={12} borderRadius={8} mx={4} my={2}>
                    
                    <Flex direction={'column'}>
                    {invoices && invoices.length > 0 ? (
                        invoices.map(invoice => (
                            <Flex justifyContent={'space-between'} mb={4} key={invoice.id}>
                                <Text fontWeight={'extrabold'} color={'gray'} fontSize={'1rem'}>
                                    {format(new Date(invoice.invoice_date * 1000), 'MM/dd/yyyy')}
                                </Text>
                                <Text fontWeight={'bold'} color={'white'}>${invoice.amount.toFixed(2)}</Text>
                                <Text fontWeight={'bold'} color={'white'}>{invoice.status.toUpperCase()}</Text>
                            </Flex>
                        ))
                    ) : (
                        <Text fontWeight={'extrabold'} color={'gray'} fontSize={'1rem'} textAlign={'center'}>
                            You have no past or upcoming payments
                        </Text>
                    )}
                    </Flex>
                </Flex>
              </Flex>}
              
              
          </Flex>
        </Box>
        </Skeleton>
    </Box>
  )
}

export default Home