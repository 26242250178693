import { Box, Flex, Text } from '@chakra-ui/react';

const Redirect = () => {
  

  return (
    <Box bg='black' height={'100vh'} display="flex" justifyContent="center" alignItems="center">
        <Box w={["90%", "80%", "70%"]} margin='0 auto'>
          <Flex direction='column' alignItems="center" spacing={6}>
            
            <Box p={5} borderRadius={8} bg={'#212121'}>
              <Text fontSize={'1.2rem'} fontWeight={'extrabold'} textAlign={'center'} color={'white'}>
                  Please close browser to return to Locseat App.
              </Text>
            </Box>


          </Flex>
        </Box>
    </Box>
  )
}

export default Redirect;
