import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyB-xIfg61gvGdbnyo2-3bKHBzeqesnwN7E",
  authDomain: "locseatapp-4c701.firebaseapp.com",
  projectId: "locseatapp-4c701",
  storageBucket: "locseatapp-4c701.appspot.com",
  messagingSenderId: "770978148715",
  appId: "1:770978148715:web:5b8c6683cc3c98c5cd9c2e",
  measurementId: "G-MLGE0P2Q8X"
};

export const firebaseApp = initializeApp(firebaseConfig);
