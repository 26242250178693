import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useParams, Link } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
 
const SubscriptionSuccess = () => {
  const { user_id } = useParams();

  useEffect(() => {
    const updateSubscriptionStatus = async () => {
      try {
        const response = await axios.post('https://3a0e-2601-190-402-a40-c05e-a226-ddc0-b8a8.ngrok.io' + '/api/update-status', { userId: user_id });
        if (response.data.success) {
          console.log('User status updated successfully');
        } else {
          console.error('Error updating user status:', response.data.message);
        }
      } catch (error) {
        console.error('Error sending request to update user status:', error);
      }
    };

    updateSubscriptionStatus();
  }, [user_id]);

  return (
    <Box bg='black' height={'100vh'} display="flex" justifyContent="center" alignItems="center">
        <Box w={["90%", "80%", "70%"]} margin='0 auto'>
          <Flex direction='column' alignItems="center" spacing={6}>
            
            <Box p={5} borderRadius={8} bg={'#212121'}>
              <Text fontSize={'1.2rem'} fontWeight={'extrabold'} textAlign={'center'} color={'white'}>
                  Your PRO Subscription is now active
              </Text>
            </Box>

            <Button mt={6} size="md" bg='#883D1A' color={'white'} onClick={() => window.location.href=`/${user_id}`}>
                Return to Dashboard
            </Button>

          </Flex>
        </Box>
    </Box>
  )
}

export default SubscriptionSuccess;
